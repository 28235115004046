import { render, staticRenderFns } from "./card.vue?vue&type=template&id=0b73b7f3&lang=pug&"
import script from "./card.vue?vue&type=script&lang=coffee&"
export * from "./card.vue?vue&type=script&lang=coffee&"
import style0 from "./card.vue?vue&type=style&index=0&id=0b73b7f3&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports